<template>
  <section
    ref="contactPart"
    class="contact-part"
  >
    <div class="contact-part-container">
      <div class="contact">
        <h1 class="C">
          聯絡
        </h1>
        <h1 class="M">
          湖口人
        </h1>
      </div>
      <div class="pics-wrapper">
        <img
          class="hero-pics c-pic p-one"
          src="../../images/01.jpg"
          alt="01"
        >
        <img
          class="hero-pics c-pic p-two"
          src="../../images/02.jpg"
          alt="02"
        >
        <img
          class="hero-pics c-pic p-three"
          src="../../images/03.jpg"
          alt="03"
        >
        <img
          class="hero-pics c-pic p-four"
          src="../../images/04.jpg"
          alt="04"
        >
        <img
          class="hero-pics c-pic p-five"
          src="../../images/05.jpg"
          alt="05"
        >
        <img
          class="hero-pics c-pic p-six"
          src="../../images/06.jpg"
          alt="06"
        >
        <img
          class="hero-pics c-pic p-seven"
          src="../../images/07.jpg"
          alt="07"
        >
      </div>
      <div class="info">
        <div
          class="email"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <!-- <a
              href="mailto:easonlu0425@gmail.com?subject=I'm interested in hiring you!"
              >easonlu0425@gmail.com</a
            > -->
        </div>
        <div class="phone">
          (+886) 975-xxx-yyy
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";

export default {
  props: {
    loaddone: Boolean,
  },
  mounted() {
    this.contactAnim()
  },
  beforeDestroy() {
  },
  methods: {
    contactAnim() {
      gsap.set([".info", ".c-pic"], { scale: 0 });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".contact-part-container",
          scrub: true,
          start: "bottom bottom",
          end: "+=3000",
          pin: ".contact-part",
        },
      });
      tl.to(".C", { x: -1500 })
        .to(".M", { x: 1500 }, "-=")
        .to(".p-three", { scale: 1, x: 100, y: -300, opacity: 0.5 }, "-=")
        .to(".p-one", { scale: 1, x: 250, y: 0, opacity: 0.5 }, "-=")
        .to(".p-seven", { scale: 1, x: 180, y: 300, opacity: 0.5 }, "-=")
        .to(".p-six", { scale: 1, x: -480, y: 10, opacity: 0.5 }, "-=")
        .to(".p-four", { scale: 1, x: -300, y: 290, opacity: 0.5 }, "-=")
        .to(".p-two", { scale: 1, x: -340, y: -270, opacity: 0.5 }, "-=")
        .to(".info", { scale: 2 });
    },
  },
};
</script>
  
<style scoped>

.contact-part {
  height: 100vh;
  position: relative;
  background-color: #000000;
  display: flex;
  z-index: 10;

  & > .contact-part-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;

    & > .contact {
      text-align: center;
      width: 100vw;

      & > h1 {
        font-size: 14vh;
        color: #e0e0e0;
      }
    }


    & > .pics-wrapper {
      z-index: -2;
      position: absolute;
      top: 0%;
      left: 0%;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      
      & > .hero-pics {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 100px);
        width: 30vw;
        height: 40vw;
        overflow: hidden;
        object-fit: cover;
      }

      & > .c-pic {
        transform: translate(-50%, -50%);
      }
    }

    & > .pics-wrapper img:nth-child(2) {
      z-index: 1;
    }

    & > .info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #e0e0e0;
      font-size: 1rem;
      text-align: center;

      & > .email a {
        text-decoration: none;
        color: #e0e0e0;
        border-bottom: 1px solid;
      }
    }

  }
}

/* RWD */
@media screen and (min-width: 992px) { 
  .contact-part {
    & > .contact-part-container {     
      & > .pics-wrapper {
        
        & > .hero-pics {
          height: 268px;
          width: 201px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .contact-part {
    & > .contact-part-container {     
      & > .contact {
        

        & > h1 {
          font-size: 20vw;
          color: #e0e0e0;
        }
      }

      & > .pics-wrapper {
        
        & > .hero-pics {
          height: 268px;
          width: 201px;
        }
      }
      & > .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #e0e0e0;
        font-size: 1.2rem;
      }
    }
  }
}

</style>
  