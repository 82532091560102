<template>
  <div class="scroll-container">
    <page1
      :loaddone="loaddone"
      @mouseenter="onHover"
      @mouseleave="onLeave"
    />
    <page2
      :loaddone="loaddone"
      @mouseenter="onHover"
      @mouseleave="onLeave"
    />
    <page3
      :loaddone="loaddone"
      @mouseenter="onHover"
      @mouseleave="onLeave"
    />
    <page4
      :loaddone="loaddone"
      @mouseenter="onHover"
      @mouseleave="onLeave"
    />
    <page5
      :loaddone="loaddone"
      @mouseenter="onHover"
      @mouseleave="onLeave"
    />
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import page1 from "../components/home/page1.vue";
import page2 from "../components/home/page2.vue";
import page3 from "../components/home/page3.vue";
import page4 from "../components/home/page4.vue";
import page5 from "../components/home/page5.vue";

export default {
  components: {
    page1,page2,page3,page4,page5,
  },
  props: {
    loaddone: Boolean,
  },
  mounted() {
  },
  beforeDestroy() {
    ScrollTrigger.killAll();
  },
  methods: {
    onHover() {
      this.$emit("handle-mouse-hover");
    },
    onLeave() {
      this.$emit("handle-mouse-leave");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@200;400;500;700&family=Noto+Sans:wght@500;700&family=Nunito+Sans:wght@400;700&display=swap");

.scroll-container {
  height: auto;
}

section {
  height: 100vh;
}

/* .scroll-item {
  height: 100%;
} */
</style>
