<template>
  <section
    id="welcome-part"
    ref="welcomePart"
    class="scroll-item welcome-part"
  >
    <p class="welcome">
      歡迎來到
    </p>
    <p class="welcome">
      湖口人
    </p>
    <p class="welcome">
      幻想世界
    </p>
  </section>
</template>

<script>

export default {
  props: {
    loaddone: Boolean,
  },
  methods: {},
};
</script>
  
<style scoped>
.welcome-part {
  position: relative;
  top: 0;
  height: auto;
  height: 105vh;
  padding-bottom: 20vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 10;

  & > .welcome {
    z-index: 2;
    font-size: 16vw;
    line-height: 1.1;
    font-weight: 700;
    color: white;
  }

  & > .welcome:nth-child(2) {
    z-index: 2;
    font-size: 16vw;
    line-height: 0.9;
    font-weight: 400;
    color: white;
  }

}

/* RWD */
@media screen and (min-width: 992px) {
  
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  
}

</style>
  