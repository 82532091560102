<template>
  <section class="scroll-item more-part">
    <div class="giving">
      <div class="giving-word here">
        我們在這裡
      </div>
      <div
        ref="giving"
        class="giving-word give"
      >
        給你
      </div>
      <div class="adjs">
        <div class="adj">
          獨特的
        </div>
        <div class="adj">
          極佳的
        </div>
        <div class="adj">
          奇妙的
        </div>
        <div class="adj">
          妖嬈的
        </div>
      </div>
      <div class="giving-word exp">
        經歷
      </div>
    </div>
    <div
      ref="more"
      class="more"
    >
      <h1>了解更多關於湖口的</h1>
      <div
        ref="meContainer"
        class="me-container"
      >
        <div
          class="me one"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <router-link :to="{ name: 'vip' }">
            #我是湖口人團服優惠
          </router-link>
        </div>
        <div
          class="me one"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <router-link :to="{ name: 'travel' }">
            #旅遊
          </router-link>
        </div>
        <div
          class="me two"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <router-link :to="{ name: 'food' }">
            #美食
          </router-link>
        </div>
        <div
          class="me three"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <router-link :to="{ name: 'culture' }">
            #文化
          </router-link>
        </div>
        <div
          class="me four"
          @mouseenter="$emit('mousehover')"
          @mouseleave="$emit('mouseleave')"
        >
          <router-link :to="{ name: 'other' }">
            #其它
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";

export default {
  props: {
    loaddone: Boolean,
  },
  mounted() {
    this.meAnim();
    this.introAnim();
  },
  beforeDestroy() {
  },
  methods: {
    meAnim() {
      const givingWords = gsap.utils.toArray(".giving-word");
      gsap.set(".me", { opacity: 0, x: -100 });
      givingWords.forEach((word) => {
        gsap.to(word, {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: this.$refs.giving,
          },
        });
      });
      const meTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".me",
          start: "bottom bottom",
        },
      });
      const meIn = {
        opacity: 1,
        x: 0,
      };

      meTL
        .to(".one", meIn)
        .to(".two", meIn)
        .to(".three", meIn)
        .to(".four", meIn);
    },
    introAnim() {
      gsap.set(".giving-word", { opacity: 0, y: 100 });

      gsap.set(".adj", { opacity: 0, y: 100 });
      const adjs = gsap.utils.toArray(".adj");
      const adjTL = gsap.timeline({ repeat: -1 });
      const adjIn = { opacity: 1, y: -1, duration: 1 };
      const adjOut = { opacity: 0, y: -100 };
      adjTL
        .to(adjs[0], adjIn)
        .to(adjs[0], adjOut)
        .to(adjs[1], adjIn)
        .to(adjs[1], adjOut)
        .to(adjs[2], adjIn)
        .to(adjs[2], adjOut)
        .to(adjs[3], adjIn)
        .to(adjs[3], adjOut);
    },
  },
};
</script>
  
<style scoped>

.more-part {
  background-color: #000000;
  color: white;
  height: 100vh;
  position: relative;
  z-index: 10;

  & > .giving {
    position: relative;
    font-size: 7vh;
    font-weight: 300;
    margin: 0 1rem;



    & > .give {
      padding-left: 10vw;
      position: relative;
    }

    & > .give::before {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #ff5029;
      position: absolute;
      left: 5%;
      top: 50%;
    }

    & > .here {
      padding-left: 5vw;
    }



    & > .adjs {
      position: relative;
      height: 14vh;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      font-weight: 500;

      & > .adj {
        position: absolute;
        left: 20%;
        top: 0;
      }
    }
    & > .exp {
      padding-left: 5vw;
    }
  }

  & > .more {
    border-top: 2px solid lightgray;
    margin: 0 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .me-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .me a {
        text-decoration: none;
        color: white;
      }

      & > .me a:hover {
        background: white;
        color: black;
      }
    }
  }
}

/* RWD */
@media screen and (min-width: 992px) { 

  .more-part {
    & > .giving {
      font-size: 14vh;
      text-align: center;
      display: flex;
      margin: 0 5rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & > .give {
        padding-left: 40vh;
      }

      & > .give::before {
        left: 30%;
      }

      & > .here {
        padding-left: 30vh;
      }

      & > .adjs {
        width: 100%;
        height: 18vh;
      }

      & > .exp {
        padding-left: 30vh;
      }
    }

    & > .more {
      & > .me-container {
        flex-direction: row;
        gap: 1.5rem;

        & > .me:hover {
          background-color: white;
          color: #000000;
          backdrop-filter: blur(12px);
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .more-part {
    & > .giving {
      text-align: center;
      display: flex;
      margin: 0 5rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & > .give {
        padding-left: 25vw;
      }

      & > .give::before {
        left: 20%;
      }

      & > .here {
        padding-left: 15vh;
      }

      & > .adjs {
        position: relative;
        height: 14vh;

        & > .adj {
          position: absolute;
          left: 45%;
        }
      }

      & > .exp {
        padding-left: 20vw;
      }
    }

  }
}

</style>
  