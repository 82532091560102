<template>
  <section
    id="namePart"
    ref="namePart"
    class="scroll-item name-part"
  >
    <div class="first-page">
      <div class="pics-wrapper">
        <img
          class="hero-pics n-pic"
          src="../../images/01.jpg"
          alt="01"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/02.jpg"
          alt="02"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/03.jpg"
          alt="03"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/04.jpg"
          alt="04"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/05.jpg"
          alt="05"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/06.jpg"
          alt="06"
        >
        <img
          class="hero-pics n-pic"
          src="../../images/07.jpg"
          alt="07"
        >
      </div>
      <div class="name-wrapper">
        <div class="name-bg" />
        <p class="name">
          我是湖口人,
        </p>
      </div>
      <div
        class="scroll"
        style="z-index: 1"
      >
        <svg
          width="40px"
          height="100%"
          viewBox="0 0 247 390"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 1.5;
                "
        >
          <path
            id="wheel"
            d="M123.359,79.775l0,72.843"
            style="fill: none; stroke: #fff; stroke-width: 20px"
          />
          <path
            id="mouse"
            d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
            style="fill: none; stroke: #fff; stroke-width: 20px"
          />
        </svg>

        <div style="color: white">
          Scroll down
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";

export default {
  props: {
    loaddone: Boolean,
  },
  mounted() {
    this.scatterAnim();
    this.nameAnim();
  },
  beforeDestroy() {
  },
  methods: {
    scatterAnim() {
      const pics = gsap.utils.toArray(".n-pic");
      gsap.set(pics,{opacity:0})

      const showTL = gsap.timeline();
      if (!this.loaddone) {
        showTL.set({}, {delay:3})
      } else {
        showTL.set({}, {delay:0})
      }
      showTL
        .to(pics, {
          y: -100,
          stagger: 0.02,
          opacity:1,
          onComplete: () => {
            gsap.to(pics, { opacity: 0, duration: 0.5 });
          },
        })
        .to(".name-wrapper", { opacity: 1, duration: 0.5 })
        .to(pics[0], { x: "-150%", y: "70%", opacity: 1 })
        .to(pics[1], { x: "40%", y: "10%", opacity: 1 }, "<")
        .to(pics[2], { x: "-100%", y: "-80%", opacity: 1 }, "<")
        .to(pics[3], { x: "250%", y: "-20%", opacity: 1 }, "<")
        .to(pics[4], { x: "-185%", y: "-190%", opacity: 1 }, "<")
        .to(pics[5], { x: "-380%", y: "-50%", opacity: 1 }, "<")
        .to(pics[6], { x: "80%", y: "-180%", opacity: 1 }, "<")
        .to('.scroll', {opacity:1})
    },
    nameAnim() {
      const nameTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#welcome-part',
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      });
      nameTL.to(".name-part", {
        filter: "blur(20px)",
        duration: 1,
      });
    },
  },
};
</script>
  
<style scoped>
.name-part  {
  position: sticky;
  top: 0;
  height: 100vh;


  & > .first-page {
    position: relative;
    top: 0;
    transform-style: preserve-3d;

    & > .pics-wrapper {
      z-index: -2;
      position: absolute;
      top: 0%;
      left: 0%;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      
      & > .hero-pics {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 100px);
        width: 30vw;
        height: 40vw;
        overflow: hidden;
        object-fit: cover;
      }

      & > .c-pic {
        transform: translate(-50%, -50%);
      }
    }

    & > .pics-wrapper img:nth-child(2) {
      z-index: 1;
    }


  & > .name-wrapper {
      position: relative;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;


      & > .name-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
        z-index: 0;
      }

      & > .name {
        font-size: 4rem;
        line-height: 1.1;
        font-weight: 400;
        font-family: Manrope, sans-serif;
        opacity: 1;
        color: white;
        z-index: 2;
      }
    }


    & > .scroll {
      position: absolute;
      top: 70%;
      right: 15%;
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

svg #wheel {
  animation: scroll ease 1.5s infinite;
}

/* animation */
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}

/* RWD */
@media screen and (min-width: 992px) {
  .name-part  {
    & > .first-page {
      & > .pics-wrapper {
        & >  .hero-pics {
          height: 268px;
          width: 201px;
        }
      }
      & > .name-wrapper {
        & > .name {
          font-size: 4rem;
        }
      }
    }

  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .name-part  {
    & > .first-page {
      & > .pics-wrapper {
        & >  .hero-pics {
          height: 268px;
          width: 201px;
        }
      }

      & > .name-wrapper { 
        & > .name {
          font-size: 21vw;
        }
      }
    }
  }
}

</style>
  