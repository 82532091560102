<template>
  <section
    class="container"
    data-barba="container"
  >
    <h1>選擇地點並更新地圖</h1>
    <div id="container">
      <div id="locations">
        <div
          v-for="val in locations"
          :key="val.name"
          class="location-link"
          @click="onClickLocation(val)"
        >
          {{ val.name }}
        </div>
      </div>
      <div id="tip">
        {{ tip }}
      </div>
      <iframe
        class="map"
        width="600"
        height="450"
        style="border:0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyD4A0QGKErdNtyulelmS1xRgXbMzMGE0og
            &q=${address}`"
      />
    </div> 
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      address: '欣悅髮廊',
      tip: '',

      locations: [
        { name: '欣悅髮廊', tip: `穿團服 折200
當月活動 ～ 《可以看欣悅髮廊臉書》
例如 這個月 活動85折
2800染《折2380）再折200(>)2180$` },
        { name: '藍色水母冰品', tip: `穿團服全品項免費加一種料😏` },
        { name: 'NU PASTA湖口店', tip: `穿團服(9)折優惠` },
        { name: '享 鍋精緻涮烤', tip: `⭐️我是湖口人⭐️群組(9)(5)折，
穿團服再贈送40元火鍋料一份唷
（一鍋一份）`},
        { name: '超有肉涮涮屋 湖口店', tip: `穿團服招待6昂司肉盤ㄧ盤
（牛五花或是豬梅花二擇一）` },
      ],
    };
  },
  mounted() {
    
  },
  methods: {
    onClickLocation(obj) {
      this.address = obj.name
      this.tip = obj.tip
    },
  },
};
</script>

<style scoped>
.container { 
  padding: 50px;


  #container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .map {
    width: 100%;
    height: 400px; /* 调整高度根据需要 */
  }
  .location-link {
    margin: 5px;
    padding: 5px;
    background-color: #0074e4;
    color: white;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
  }
}

</style>
